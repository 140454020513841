import React from "react";
import styled from "@emotion/styled";
import { mediaQueries } from "@src/styles/theme";
import Container from "@src/components/Container";
import { Box, Flex } from "@src/components/Boxes";
import Button from "@src/components/Button";
import SEO from "@src/components/SEO";
import Translate from "@src/components/translation/Translate";
import { graphql, useStaticQuery } from "gatsby";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const Header = styled("h1")`
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  margin: 10px 20px 20px;
  text-align: center;

  ${mediaQueries[0]} {
    font-size: 48px;
    line-height: 52px;
    margin: 10px 20px 40px;
  }
`;

type AnimationProps = {
  image_overlay: {
    file: {
      publicURL: string;
    };
  };
  image: {
    file: {
      publicURL: string;
    };
  };
};
const Animation = styled("div")<AnimationProps>`
  width: 100%;
  height: 800px;
  max-height: 50vh;
  background-image: ${(props) => `url(${props.image_overlay.file.publicURL}), url(${props.image.file.publicURL})`};
  background-repeat: no-repeat, no-repeat;
  background-position: top center, center;
  background-size: contain, contain;
`;

function OneHundred() {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_onehundred {
          image {
            id
            filename_download
            filename_disk
          }
          image_overlay {
            id
            filename_download
            filename_disk
          }
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_onehundred: { image, image_overlay },
    },
  } = data;

  return (
    <Container>
      <SEO
        title="One Hundred Percent!"
        description="One hundred percent of parents are connected to my classroom on ClassDojo!"
        image="https://static.classdojo.com/img/page_onehundred/onehundred-preview.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "One Hundred Percent!",
          description: "One hundred percent of parents are connected to my classroom on ClassDojo!",
          image: "https://static.classdojo.com/img/page_onehundred/onehundred-preview.jpg",
        }}
      />
      <Flex justifyContent="center" alignItems="center" flexDirection="column" paddingTop="25px">
        <Header>
          <Translate path={"directus.page_onehundred.title"} />
        </Header>
        <Animation image={image} image_overlay={image_overlay} />
        <Box>
          <Button big my="50px" as="a" href={"/"}>
            <Translate path={"directus.page_onehundred.button_text"} />
          </Button>
        </Box>
      </Flex>
    </Container>
  );
}

export default OneHundred;
